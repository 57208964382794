const _jsxFileName = "/usr/src/app/app/js/components/InfoRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useEffect, useRef } from "react";

import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  validationMessage: {
    border: `1px solid ${theme.palette.error.dark}`,
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.9em",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
  },
  padding: {
    padding: theme.spacing(2),
  },
  editMetaButton: {
    marginLeft: theme.spacing(1),
  },
  listMarker: {
    listStyle: "none",
    counterReset: "item",
    paddingLeft: 0,
    "& li": {
      counterIncrement: "item",
      paddingLeft: "1.5em",
      position: "relative",
      "&::before": {
        content: 'counter(item) ". "',
        fontWeight: "bold",
        position: "absolute",
        left: 0,
      },
    },
    marginBottom: theme.spacing(2),
  },
  clickableText: {
    display: "inline",
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));

export const InfoRoute = observer(function InfoRoute() {
  const app = useApp();
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  const { paragraphId } = useParams();
  const paragraphRefs = useRef({});

  useEffect(() => {
    if (paragraphId) {
      const paraRef = paragraphRefs.current[paragraphId];
      if (paraRef) {
        paraRef.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [paragraphId]);

  const scrollToSection = (i) => {
    _optionalChain([paragraphRefs, 'access', _ => _.current, 'access', _2 => _2[i], 'optionalAccess', _3 => _3.scrollIntoView, 'call', _4 => _4({ behavior: "smooth" })]);
  };

  return (
    React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
      , React.createElement(Grid, {
        ref: (el) => (paragraphRefs.current[1] = el),
        id: "section1",
        item: true,
        xs: 12,
        className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}

        , React.createElement(Typography, {
          variant: "h4",
          component: "h1",
          className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}

          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}, "Mehr zur Kreislaufwirtschaft und dem Projekt"     )
        )
      )

      , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
        , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
          , React.createElement(Grid, { container: true, spacing: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
            , React.createElement(Grid, { item: true, xs: 12, md: 7, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
              , React.createElement(Typography, {
                variant: "h5",
                component: "h2",
                style: {
                  marginBottom: "1rem",
                  color: theme.palette.primary.main,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}

                , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}, "Was ist Kreislaufwirtschaft?"  )
              )
              , React.createElement(Typography, {
                variant: "body2",
                component: "h2",
                style: { marginBottom: "0.5rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
, "Die Kreislaufwirtschaft (englisch: Circular Economy, oftmals auch Zirkularität genannt) ist ein nachhaltiges Modell der Produktion und des Verbrauchs, das Unternehmen den Weg in die ressourcenschonende Wertschöpfung eröffnet. Unser aktuell vorherrschendes, lineares Wirtschaftssystem führt nicht nur zu Energie- und Ressourcenverschwendung, sondern letztlich zur Übernutzung der endlichen Ressourcen auf der Erde. Hier wird nicht auf den Erhalt von Ressourcen geachtet und Produkte werden nach ihrem Nutzungsende oft direkt weggeworfen. "








                      , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 123}} ), "Die Kreislaufwirtschaft steht dem entgegen und erschließt für Ihr Unternehmen das zirkuläre Wirtschaften, bei dem Ressourcen in einem geschlossenen Kreislauf erhalten bleiben. Die Idee der Kreislaufwirtschaft orientiert sich an den abfallfreien Kreisläufen der Natur. Dort entstehen keine Überschüsse, da Nährstoffe verwertet werden und als Basis für neues Leben dienen. Überträgt man dieses Prinzip in die Wirtschaft, können Ressourcen geschont, schädliche Emissionen reduziert, Energieverschwendung minimiert und Abfälle vermieden werden. Dazu kommen verschiedene Strategien und Maßnahmen zum Einsatz, die Sie z. B. bei der Vermeidung bzw. Senkung von Rohstoffaufwand, der Verlängerung bzw. Intensivierung der Nutzungsphase Ihrer Produkte oder der Gewinnung von Sekundärrohstoffen unterstützen. Diese nennt man „R-Strategien“."













                     , " "
              )

              , React.createElement('figure', { style: { fontSize: "0.8rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}
                , React.createElement('img', {
                  src: require("../assets/logo/klw.jpg"),
                  width: "50%",
                  height: "50%",
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "25%",
                    objectFit: "contain",
                    marginBottom: "1rem",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}
                )
                , React.createElement('center', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}, "Schematische Darstellung der Kreislaufwirtschaft"

                )
                , React.createElement('center', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}, "(Grafik: © Jacqueline Ullmann für Fraunhofer FIT)"

                )
              )

              , React.createElement(Typography, { variant: "body2", component: "h2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}, "Einen tieferen Einblick in die Kreislaufwirtschaft gibt Ihnen unser kostenfreier Kreislaufwirtschafts-Guide:"


              )

              , React.createElement(Button, {
                variant: "text",
                color: "primary",
                size: "small",
                style: {
                  paddingLeft: 0,
                  marginTop: "1rem",
                },
                onClick: () => {
                  window.open(
                    "https://kreislaufwirtschaftsguide.fit.fraunhofer.de/",
                    "_blank"
                  );
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
, "KREISLAUFWIRTSCHAFTS-GUIDE"

              )
            )

            , React.createElement(Grid, { item: true, xs: 12, md: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 186}}
              , React.createElement(Typography, {
                variant: "h6",
                component: "h2",
                style: {
                  marginBottom: "1rem",
                  color: theme.palette.primary.main,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 187}}
, "Vorteile für KMU"

              )
              , React.createElement(Typography, {
                variant: "body2",
                component: "h2",
                style: { marginBottom: "1rem", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 197}}
, "Kleine und mittlere Unternehmen profitieren langfristig sowohl aus ökologischer als auch ökonomischer Sicht, wenn sie zirkulär wirtschaften. Die folgenden Aspekte zeigen, welchen Nutzen Sie konkret von einem Übergang in die Kreislaufwirtschaft haben:"




                , React.createElement('ol', { className: classes.listMarker, __self: this, __source: {fileName: _jsxFileName, lineNumber: 206}}
                  , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 207}}
                    , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 208}}, "Kosteneinsparungen")
                  ), "Der effizientere Umgang mit Rohstoffen in der Produktion reduziert sowohl die benötigte Rohstoffmenge als auch die Einkaufskosten. Unternehmen setzen dafür auf Eco Design-Prinzipien, effizientere Produktionsverfahren sowie die Wiederverwendung und das Recycling von Materialien. Als weiteren Effekt erzielen die effizienteren Produktions- und Wiederverwertungsprozesse geringere Abfallmengen und senken dadurch ebenfalls die Kosten für die Entsorgung."








                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 218}} )
                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 219}} )
                  , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 220}}
                    , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 221}}, "Reduktion der Rohstoffabhängigkeit"  )
                  ), "Die Nutzung von lokal bzw. national erzeugten Sekundärrohstoffen reduziert die Abhängigkeiten und Risiken bei der internationalen Rohstoffbeschaffung, wodurch Sie resilienter gegenüber Marktschwankungen und Rohstoffknappheiten werden."





                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 228}} )
                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 229}} )
                  , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 230}}
                    , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 231}}, "Wettbewerbsvorteile bei nachhaltigkeitssensitiven Kunden/Branchen"


                    )
                  ), "Unternehmen, deren Kund:innen verstärkt Wert auf Nachhaltigkeit legen oder die in umweltfokussierten Branchen tätig sind, können durch ihre zirkuläre Ausrichtung einen Wettbewerbsvorteil erlangen."




                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 240}} )
                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 241}} )
                  , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 242}}
                    , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 243}}, "Vorbereitung auf heutige und zukünftige Gesetze"     )
                  ), "Eine frühzeitige Ausrichtung auf die Strategien der Kreislaufwirtschaft ermöglicht es Ihnen, sich besser auf zukünftige gesetzliche Anforderungen im Nachhaltigkeitsbereich vorzubereiten."




                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 249}} )
                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 250}} )
                  , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 251}}
                    , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 252}}, "Attraktivität als Arbeitgeber"  )
                  ), "Unternehmen, die sich aktiv für Nachhaltigkeit einsetzen, ein ausgeprägtes Umweltbewusstsein haben und Wert auf ethische Geschäftspraktiken legen, sind oft attraktiver für potenzielle Arbeitnehmer:innen."




                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 258}} )
                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 259}} )
                )
              )
            )
          )
        )
      )

      , React.createElement(Grid, {
        ref: (el) => (paragraphRefs.current[2] = el),
        id: "section2",
        item: true,
        xs: 12,
        className: classes.marginBottom,
        spacing: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 267}}

        , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 275}}
          , React.createElement(Grid, { container: true, spacing: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 276}}
            , React.createElement(Grid, { item: true, xs: 12, md: 7, __self: this, __source: {fileName: _jsxFileName, lineNumber: 277}}
              , React.createElement(Typography, {
                variant: "h5",
                component: "h2",
                style: {
                  marginBottom: "1rem",
                  color: theme.palette.primary.main,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 278}}

                , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 286}}, "Das Projekt" )
              )
              , React.createElement(Typography, {
                variant: "body2",
                component: "h2",
                style: { marginBottom: "2rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 288}}
, "Das Kooperationsprojekts ROKK – „Reifegradbasierte Operationalisierung der Kreislaufwirtschaft für KMU“ hat das Ziel, Ihnen als KMU einen erleichterten Einstieg in das Thema Kreislaufwirtschaft zu ermöglichen und den Weg zu wirksamen, operativen Maßnahmen zu ebnen. Zu diesem Zweck entwickelten die Mittelstand-Digital Zentren Darmstadt und WertNetzWerke gemeinsam ein menschzentriertes Angebot, bestehend aus dem"






                      , " "
                , React.createElement(Typography, {
                  onClick: () => scrollToSection(3),
                  className: classes.clickableText,
                  variant: "body2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 300}}
, "Kreislaufwirtschafts-Check"

                ), " ", "zur Ermittlung des zirkulären Reifegrades und einem begleitenden dreiteiligen Workshopkonzept, das Sie an das Thema Kreislaufwirtschaft heranführt und befähigt, Ihre eigene Kreislaufwirtschaftsstrategie zu definieren sowie wirkungsvolle Maßnahmen zu identifizieren und umzusetzen."





              )
              , React.createElement('figure', {
                style: {
                  fontSize: "0.8rem",
                  marginTop: "1rem",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 313}}

                , React.createElement('img', {
                  src: require("../assets/logo/workshop.png"),
                  width: "50%",
                  height: "50%",
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "25%",
                    objectFit: "contain",
                    marginBottom: "1rem",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 319}}
                )
                , React.createElement('center', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 332}}, "Schematische Darstellung des dreiteiligen Workshop-Konzepts"

                )
                , React.createElement('center', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 335}}, "(Grafik: © Jacqueline Ullmann für Fraunhofer FIT)"

                )
              )
            )

            , React.createElement(Grid, { item: true, xs: 12, md: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 341}}
              , React.createElement(Typography, {
                variant: "h6",
                component: "h2",
                style: {
                  marginBottom: "1rem",
                  color: theme.palette.primary.main,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 342}}
, "Workshops: Verstehen, Erkennen, Umsetzen"

              )
              , React.createElement(Typography, {
                variant: "body2",
                component: "h2",
                style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 352}}
, "In drei aufeinander aufbauenden Workshops befähigen wir Sie, zirkuläre Prinzipien in Ihr Unternehmen zu tragen. "

                       , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 358}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 359}} )
                , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 360}}, "Intro-Workshop")
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 361}} ), "Zunächst erhalten Sie einen praxisnahen Einstieg in die Kreislaufwirtschaft und lernen die R-Strategien und konkrete Unternehmensbeispiele zur Umsetzung kennen. Wir erklären, wie sich Kreislaufwirtschaft und Digitalisierung gegenseitig unterstützen und ermitteln mit Ihnen zusammen klare Anpackpunkte für die Transformation Ihres Unternehmens."






                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 368}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 369}} )
                , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 370}}, "Reifegradbestimmung " )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 371}} ), "Dann werfen wir gemeinsam einen Blick auf Ihr Unternehmen und ermitteln, wie zirkulär Sie bereits aufgestellt sind. Dabei hilft uns der Kreislaufwirtschafts-Check."



                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 375}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 376}} )
                , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 377}}, "Workshop zur Potenzialanalyse und Umsetzungsstrategie"    )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 378}} ), "Im nächsten Schritt identifizieren wir gemeinsam mit Ihnen Strategien und Maßnahmen, um die Zirkularität Ihres Unternehmens zu steigern. Auf Basis der Reifegradermittlung priorisieren wir konkrete Umsetzungsprojekte und unterstützen Sie bei der Durchführung dieser."





                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 384}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 385}} )
              )
              , React.createElement(Typography, {
                variant: "body2",
                component: "h2",
                style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 387}}
, "Sie interessieren sich für die Workshops oder Materialien?"
                       , " "
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 393}} ), "Kontaktieren Sie uns gerne:"

                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 395}} )
                , React.createElement(Button, {
                  variant: "text",
                  color: "primary",
                  size: "small",
                  startIcon: React.createElement(ArrowForwardIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 400}} ),
                  style: {
                    paddingLeft: 0,
                  },
                  onClick: () => {
                    navigate("/contact");
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 396}}
, "Kontakt"

                )
              )
            )
          )
        )
      )

      , React.createElement(Grid, {
        ref: (el) => (paragraphRefs.current[3] = el),
        id: "section3",
        item: true,
        xs: 12,
        className: classes.marginBottom,
        spacing: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 416}}

        , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 424}}
          , React.createElement(Grid, { container: true, spacing: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 425}}
            , React.createElement(Grid, { item: true, xs: 12, md: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 426}}
              , React.createElement(Typography, {
                variant: "h5",
                component: "h2",
                style: {
                  marginBottom: "1rem",
                  color: theme.palette.primary.main,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 427}}

                , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 435}}, "Was ist der Kreislaufwirtschafts-Check?"   )
              )
              , React.createElement(Typography, {
                variant: "body2",
                component: "h2",
                style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 437}}
, "Der Kreislaufwirtschafts-Check dient dazu, Ihren zirkulären Reifegrad zu ermitteln, eigene Stärken und Potenziale im Bereich Nachhaltigkeit zu erkennen und fundierte Entscheidungen über weitere zirkuläre Schritte zu treffen. Der integrierte Maßnahmenkatalog bietet eine Sammlung von Lösungsansätzen und Best Practices, die Sie bei der Lösung Ihrer individuellen Herausforderungen unterstützt."







                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 449}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 450}} ), "Der Kreislaufwirtschafts-Check selbst ist ein webbasiertes Tool, mit dem man das im Rahmen des Zukunft-Projekts entwickelte"

                        , " "
                , React.createElement(Typography, {
                  onClick: () => scrollToSection(4),
                  className: classes.clickableText,
                  variant: "body2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 453}}
, "Reifgradmodell"

                ), " ", "über die benutzerfreundliche Oberfläche einfach und schrittweise durchlaufen kann."


              )

              , React.createElement(Typography, {
                variant: "h6",
                component: "h2",
                style: {
                  marginBottom: "1rem",
                  color: theme.palette.primary.main,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 464}}
, "Welche Funktionen hat der Kreislaufwirtschafts-Check?"

              )

              , React.createElement(Typography, {
                variant: "body2",
                component: "h2",
                style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 475}}
, "Kernfunktion ist die qualitative Bestimmung des zirkulären Reifegrads durch die Beantwortung gezielter Fragen. Darüber hinaus werden Berichte und Grafiken generiert, die Unternehmen helfen, ihre Potenziale zur Verbesserung zu identifizieren."




                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 484}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 485}} ), "Wird ein Unternehmensaccount angelegt, können mehrere Kreislaufwirtschafts-Checks angelegt und die Ergebnisse gespeichert werden. So können auch unterschiedliche Abteilungen oder verschiedene Zeitpunkte miteinander verglichen werden. Jeder Kreislaufwirtschafts-Check kann mit Kolleg:innen geteilt werden, sodass der Check im Team ausgefüllt werden kann."






                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 492}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 493}} ), "Das Tool wurde in aktuellen Web-Frameworks umgesetzt und lässt sich einfach in bestehende Systeme und Plattformen integrieren."


                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 496}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 497}} ), "In diesem Fall können die Betreiber:innen von Autorentool-Features profitieren und das Tool eigenständig und flexibel konfigurieren: Kategorien können angepasst oder Fragen ergänzt werden, um zusätzliche Aspekte und Themen zu berücksichtigen. So kann der Kreislaufwirtschafts-Check auch in anderen Branchen oder mit abweichenden Anforderungen eingesetzt werden."







              )
            )

            , React.createElement(Grid, { item: true, xs: 12, md: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 508}}
              , React.createElement(Typography, {
                variant: "h6",
                component: "h2",
                style: {
                  marginBottom: "1rem",
                  color: theme.palette.primary.main,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 509}}
, "Was passiert mit meinen Daten?"

              )

              , React.createElement(Typography, {
                variant: "body2",
                component: "h2",
                style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 520}}
, "Um den langfristigen Zugriff auf durchgeführte Checks sicherstellen zu können, werden die Daten auf einem Server der Fraunhofer Gesellschaft gespeichert. Die Daten werden nicht an Dritte weitergeleitet und für keine weitergehenden Analysen genutzt. Sie dienen rein dem Zugriff durch die registrierten Unternehmen."






              )

              , React.createElement(Typography, {
                variant: "h6",
                component: "h2",
                style: {
                  marginBottom: "1rem",
                  color: theme.palette.primary.main,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 533}}
, "Was ist der Kreislaufwirtschafts-Check NICHT?"

              )

              , React.createElement(Typography, {
                variant: "body2",
                component: "h2",
                style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 544}}
, "Der Kreislaufwirtschafts-Check basiert auf einem Reifegradmodell, das für produzierende Unternehmen nach dem aktuellen wissenschaftlichen Kenntnisstand und in Zusammenarbeit mit assoziierten kreislaufwirtschaftlichen Unternehmen entwickelt und auf seine Praxistauglichkeit getestet wurde. Dies wird zukünftig Anpassungen unterliegen müssen. Was heute als höchster Reifegrad angesehen wird, kann zukünftig vielleicht nicht mehr ausreichend sein, um als vollkommen zirkulär zu gelten."









                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 558}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 559}} ), "Darüber hinaus ist der Kreislaufwirtschafts-Check ein rein qualitatives Tool, es dient nicht der Erfassung oder dem Monitoring konkreter (Kenn-) Zahlen. Bestimmte übergreifende Kontexte können durch das Tool ebenfalls nicht adressiert werden, sodass das Thema Greenwashing stets beachtet werden muss."






                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 566}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 567}} ), "Der ermittelte zirkuläre Reifegrad soll von den Unternehmen zur internen Orientierung, bzw. zur Orientierung in ihren Wertschöpfungsnetzwerk genutzt werden. Eine Veröffentlichung der Ergebnisse zu Marketingzwecken ist nicht vorgesehen."




              )

              , React.createElement(Typography, {
                variant: "body2",
                component: "h2",
                style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 574}}
)
            )
          )
        )
      )

      , React.createElement(Grid, {
        ref: (el) => (paragraphRefs.current[4] = el),
        id: "section4",
        item: true,
        xs: 12,
        className: classes.marginBottom,
        spacing: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 584}}

        , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 592}}
          , React.createElement(Grid, { container: true, spacing: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 593}}
            , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 594}}
              , React.createElement(Typography, {
                variant: "h5",
                component: "h2",
                style: {
                  marginBottom: "1rem",
                  color: theme.palette.primary.main,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 595}}

                , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 603}}, "Das Reifegradmodell hinter dem Kreislaufwirtschafts-Check"    )
              )
              , React.createElement(Typography, {
                variant: "body2",
                component: "h2",
                style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 605}}
, "Das ganzheitliche zirkuläre Reifegradmodell, das wir zusammen mit produzierenden Unternehmen nach dem aktuellen wissenschaftlichen Kenntnisstand entwickelt und auf seine Praxistauglichkeit getestet haben, besteht aus"



                    , " "
                , app.benchmarks.dimensions.length, " Dimensionen: "  , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 614}}, " Produkt" ), ","
                , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 615}}, " Prozess" ), " und "  , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 615}}, " Organisation" ), ". Diesen sind insgesamt"   , " "
                , app.benchmarks.categories.length + ` `, " Handlungsfelder zugeordnet, darunter "
                  , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 617}}, "Material"), ",", " "
                , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 618}}, "Supply Chain und Logistik"   ), ", oder"
                , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 619}}, " Management" ), ", welche wiederum in sogenannte Reifeelemente unterteilt sind. Zu jedem dieser"
                    , " "
                , app.benchmarks.questions.length + ` `, " Reifeelemente wird im"   , " "
                , React.createElement(Typography, {
                  onClick: () => scrollToSection(3),
                  className: classes.clickableText,
                  variant: "body2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 622}}
, "Kreislaufwirtschafts-Check"

                ), " ", "eine Frage mit verschiedenen Antwortmöglichkeiten gestellt. So wird der zirkuläre Reifegrad Ihres Unternehmens qualitativ bewertet und Sie erhalten eine übersichtliche Darstellung Ihrer Zirkularität – inkl. Lösungsansätzen."




              )

              , React.createElement(Grid, { container: true, spacing: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 635}}
                , React.createElement(Grid, { item: true, xs: 12, md: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 636}}
                  , React.createElement(Typography, {
                    variant: "h6",
                    component: "h2",
                    style: {
                      marginBottom: "1rem",
                      color: theme.palette.primary.main,
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 637}}
, "Die Reifegrade"

                  )
                  , React.createElement(Typography, {
                    variant: "body2",
                    component: "h2",
                    style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 647}}
, "Die Bewertung des zirkulären Reifegrades Ihres Unternehmens erfolgt basierend auf fünf Reifegradstufen, von Stufe 0 (Passiv) bis Stufe 4 (Zirkulär)."



                  )
                  , React.createElement(Typography, {
                    variant: "body2",
                    component: "h2",
                    style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 656}}

                    , React.createElement('ul', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 661}}
                      , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 662}}
                        , React.createElement('strong', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 663}}, "Stufe 0 – Reifegrad 'Passiv':"    ), " Es liegt kein Verständnis von zirkulärem Wirtschaften vor und das Thema ist nicht im Unternehmen integriert. Es existieren keine konkreten Projekte zur Integration von Kreislaufwirtschaft im Unternehmen."




                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 668}} )
                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 669}} )
                      )
                      , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 671}}
                        , React.createElement('strong', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 672}}, "Stufe 1 – Reifegrad 'Entwickelnd':"    ), " Im Unternehmen liegen einzelne Bemühungen zur Steigerung der Zirkularität vor. Allerdings sind diese nicht vernetzt und systematisch. Es wird in einzelnen Unternehmensbereichen am Thema gearbeitet."




                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 677}} )
                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 678}} )
                      )
                      , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 680}}
                        , React.createElement('strong', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 681}}, "Stufe 2 – Reifegrad 'Systematisch':"    ), " Es existieren konkrete Maßnahmen zur Steigerung der Zirkularität, welche im Unternehmen systematisch umgesetzt werden. Stakeholder werden teilweise mit einbezogen und das Konzept der Kreislaufwirtschaft wird von diesen verstanden."





                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 687}} )
                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 688}} )
                      )
                      , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 690}}
                        , React.createElement('strong', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 691}}, "Stufe 3 – Reifegrad 'Integriert':"    ), " Es existieren konkrete, ganzheitliche Maßnahmen gemeinsam im Netzwerk mit Partnern und Stakeholdern, um die Zirkularität zu steigern. Zirkuläres Wirtschaften ist tief in das Unternehmensdenken integriert und wird von allen verfolgt."





                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 697}} )
                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 698}} )
                      )
                      , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 700}}
                        , React.createElement('strong', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 701}}, "Stufe 4 – Reifegrad 'Zirkulär':"    ), " Alle Bereiche des Unternehmens sind ganzheitlich auf ein zirkuläres Wirtschaften ausgelegt. Kreislaufwirtschaft ist die Basis des Unternehmens und des Unternehmenserfolgs. Im Netzwerk und mit allen Stakeholdern werden Aktivitäten im Sinne der Kreislaufwirtschaft weiter verbessert."






                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 708}} )
                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 709}} )
                      )
                    )
                  )
                )

                , React.createElement(Grid, { item: true, xs: 12, md: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 715}}
                  , React.createElement(Typography, {
                    variant: "h6",
                    component: "h2",
                    style: {
                      marginBottom: "1rem",
                      color: theme.palette.primary.main,
                      textAlign: "left",
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 716}}
, "Die Dimensionen und Handlungsfelder"

                  )
                  , React.createElement(Typography, {
                    variant: "body2",
                    component: "h2",
                    style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 727}}
, "Das Reifegradmodell bewertet den zirkulären Reifegrad Ihres Unternehmens anhand von "

                       , app.benchmarks.dimensions.length, " ", "Dimensionen mit "
                      , app.benchmarks.categories.length, " ", "Handlungsfeldern und "
                      , app.benchmarks.questions.length, " ", "Reifeelementen."

                  )
                  , React.createElement(Typography, {
                    variant: "body2",
                    component: "h2",
                    style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 738}}

                    , React.createElement('ul', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 743}}
                      , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 744}}, "In der "
                          , React.createElement('strong', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 745}}, "Produkt-Dimension"), " wird untersucht und bewertet, inwieweit Sie bei Ihren Produkten Prinzipien und Ansätze zur Kreislaufwirtschaft berücksichtigen. So wie Produkte die Grundlage in jedem Geschäftsmodell produzierender Unternehmen bilden, sind auch die Gestaltung und Eigenschaften eines Produkts die entscheidende Grundlage für mehr Nachhaltigkeit, indem auf langlebige Produktgestaltung sowie Reparatur-, Wiederverwendungs- und Recyclingmöglichkeiten geachtet wird. Je höher der Produkt-Reifegrad, desto besser ist die Kreislauffähigkeit des Produkts – die Grundlage für minimierte Ressourcenverschwendung und reduzierte Neuproduktionen."












                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 758}} )
                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 759}} )
                      )
                      , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 761}}, "In der "
                          , React.createElement('strong', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 762}}, "Prozess-Dimension"), " werden die betrieblichen Abläufe, wie z. B. die Produktion, aber auch die Lieferkette und Logistik, untersucht und bewertet. Effiziente und ressourceneffektive Prozesse im gesamten Unternehmen, die auf Zirkularität ausgelegt sind und z. B. Material- und Energieverbrauch minimieren, bilden die zweite Grundlage für mehr Nachhaltigkeit. Erst, wenn sämtliche Prozesse im Unternehmen zirkulär ausgerichtet sind, können Sie das tatsächliche Potenzial der Kreislaufwirtschaft nutzen. Je höher der Prozess-Reifegrad, desto zirkulärer sind Ihre Produktionsprozesse. Wird dieser Reifegrad gesteigert, werden auch die Prozesse automatisch effizienter."













                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 776}} )
                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 777}} )
                      )
                      , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 779}}, "In der "
                          , React.createElement('strong', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 780}}, "Organisations-Dimension"), " werden die interne Kultur, Struktur und das Management in Ihrem Unternehmen untersucht und bewertet. Für die Umsetzung von Kreislaufwirtschaftsstrategien ist eine Integration der Kreislaufwirtschaft in Ihre Unternehmensstrategie, Schulungen Ihrer Mitarbeitenden und die Schaffung einer Innovationskultur essenziell. Je höher der Organisations-Reifegrad, desto proaktiver ist die Herangehensweise an die Kreislaufwirtschaft. Zudem gibt der Reifegrad Auskunft, wie ausgeprägt das Bewusstsein des Managements und der Mitarbeitenden für nachhaltige Praktiken ist."











                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 792}} )
                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 793}} )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  );
});
