const _jsxFileName = "/usr/src/app/app/js/components/ThemeProvider.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from "@material-ui/core/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(205, 10, 30)",
      contrastText: "#fff",
    },
    secondary: {
      main: "rgb(0, 123, 196)",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "inherit",
  },
});

export const ThemeProvider = observer(function ThemeProvider({ children }) {
  const app = useApp();

  return (
    React.createElement(MaterialThemeProvider, { theme: theme, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
      , React.createElement(StyledThemeProvider, { theme: theme, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, children)
    )
  );
});
