const _jsxFileName = "/usr/src/app/app/js/components/EvaluationChart.tsx";import { observer } from "mobx-react-lite";
import React from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler,
} from "chart.js";
import { useTheme } from "@material-ui/core/styles";

ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler
);










export const EvaluationChart = observer(function EvaluationChart({
  labels,
  evaluation,
  // checkedOtherCompany,
  // checkedSameBranch,
  // evaluationOtherCompany,
  // evaluationSameBranch,
}) {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  const hexToRgb = (hex) => {
    const fallback = [0, 123, 196];

    if (hex.startsWith("rgb")) {
      const matches = hex.match(/\d+/g);
      if (!matches || matches.length < 3) return fallback;

      const r = parseInt(matches[0], 10);
      const g = parseInt(matches[1], 10);
      const b = parseInt(matches[2], 10);

      if (isNaN(r) || isNaN(g) || isNaN(b)) return fallback;
      return [r, g, b];
    }

    const hexClean = hex.replace(/^#/, "");
    if (!/^([0-9A-F]{3}){1,2}$/i.test(hexClean)) return fallback;

    const fullHex =
      hexClean.length === 3
        ? hexClean
            .split("")
            .map((c) => c + c)
            .join("")
        : hexClean;

    const r = parseInt(fullHex.substring(0, 2), 16);
    const g = parseInt(fullHex.substring(2, 4), 16);
    const b = parseInt(fullHex.substring(4, 6), 16);

    return [r, g, b];
  };

  const [r, g, b] = hexToRgb(primaryColor);

  const objCompany = {
    label: "In diesem KLW-Check",
    data: evaluation,
    fill: true,
    backgroundColor: `rgba(${r}, ${g}, ${b}, 0.3)`,
    borderColor: primaryColor,
    pointBackgroundColor: primaryColor,
    pointBorderColor: theme.palette.primary.contrastText || "#fff",
    pointHoverBackgroundColor: theme.palette.primary.contrastText || "#fff",
    pointHoverBorderColor: primaryColor,
  };

  // const objOtherCompany = {
  //   label: "Andere Unternehmen",
  //   data: evaluationOtherCompany,
  //   fill: true,
  //   backgroundColor: "rgba(0, 123, 196, 0.3)",
  //   borderColor: "rgb(0, 123, 196)",
  //   pointBackgroundColor: "rgb(0, 123, 196)",
  //   pointBorderColor: "#fff",
  //   pointHoverBackgroundColor: "#fff",
  //   pointHoverBorderColor: "rgb(0, 123, 196)",
  // };

  // const objSameBranch = {
  //   label: "Andere Unternehmen mit gleicher Branche",
  //   data: evaluationSameBranch,
  //   fill: true,
  //   backgroundColor: "rgba(124, 184, 192, 0.3)",
  //   borderColor: "rgb(124, 184, 192)",
  //   pointBackgroundColor: "rgb(124, 184, 192)",
  //   pointBorderColor: "#fff",
  //   pointHoverBackgroundColor: "#fff",
  //   pointHoverBorderColor: "rgb(124, 184, 192)",
  // };

  const datasets = [objCompany];
  // if (checkedOtherCompany) {
  //   datasets.push(objOtherCompany);
  // }
  // if (checkedSameBranch) {
  //   datasets.push(objSameBranch);
  // }

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    scale: {
      min: 0,
      max: 4,
      step: 1,
      ticks: {
        stepSize: 1,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 16,
          },
        },
      },
    },
  };

  return React.createElement(Radar, { data: data, options: options, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}} );
});
