const _jsxFileName = "/usr/src/app/app/js/components/BenchmarkEditOverviewRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useEffect, useRef, useState } from "react";

import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Tooltip,
  withStyles,
  IconButton,

  createStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import InfoIcon from "@material-ui/icons/Info";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  validationMessage: {
    border: `1px solid ${theme.palette.error.dark}`,
    background: theme.palette.primary.main,
    color: theme.palette.error.contrastText,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.9em",
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
    textAlign: "left",
  },
  padding: {
    padding: theme.spacing(2),
    height: "100%",
  },
  editMetaButton: {
    marginLeft: theme.spacing(1),
  },
  toolTip: {
    paddingLeft: "0.1rem",
    paddingTop: "0.5rem",
  },
  content: {
    marginBottom: "1rem",
    overflow: "hidden",
    transition: "max-height 0.3s ease",
  },
  expanded: {
    maxHeight: "none",
  },
  collapsed: {
    maxHeight: "250px",
  },
}));

const CustomLinearProgress = withStyles((theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
    },
  })
)(LinearProgress);





export const BenchmarkEditOverviewRoute = observer(
  function BenchmarkEditOverviewRoute() {
    const app = useApp();
    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();
    const sectionRef = useRef(null);

    const [expandedDimensions, setExpandedDimensions] =
      useState({});

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const validation = app.benchmarks.validateCurrentBenchmark();

    const handleSeeMore = (objectId) => {
      setExpandedDimensions((prev) => ({
        ...prev,
        [objectId]: !prev[objectId],
      }));
    };

    const CustomTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(15),
        border: `5px solid ${theme.palette.primary.main}`,
      },
    }))(Tooltip);

    const scrollToRef = () => {
      _optionalChain([sectionRef, 'access', _ => _.current, 'optionalAccess', _2 => _2.scrollIntoView, 'call', _3 => _3({ behavior: "smooth" })]);
    };

    return (
      React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}
        , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 128}}
          , React.createElement(Typography, {
            variant: "h4",
            component: "h1",
            className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}

            , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}, "Kreislaufwirtschafts-Check"

              , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
                , app.benchmarks.benchmarks[app.benchmarks.currentBenchmark]
                  .alt_name
                  ? ` ${
                      app.benchmarks.benchmarks[
                        app.benchmarks.currentBenchmark
                      ].alt_name
                    }`
                  : null
              )
            )
          )
        )

        , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}
          , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
            , React.createElement(Typography, { variant: "body1", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 152}}
              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}, "Status: " ), " "
              , Object.keys(
                app.benchmarks.benchmarks[app.benchmarks.currentBenchmark]
                  .answers
              ).length +
                " / " +
                app.benchmarks.questions.length, " ", "Fragen beantwortet"

              , Object.keys(
                app.benchmarks.benchmarks[app.benchmarks.currentBenchmark]
                  .answers
              ).length === 0 ? (
                React.createElement(React.Fragment, null
                  , React.createElement(Typography, { variant: "body1", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}, "In diesem Kreislaufwirtschafts-Check werden Ihnen insgesamt"
                         , " "
                    , app.benchmarks.questions.length, " Fragen zu verschiedenen Handlungsfeldern in den "
                       , app.benchmarks.dimensions.length, " ", "Dimensionen "
                     , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}, "Produkt"), ", " , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}, "Prozess"), " und" , " "
                    , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 171}}, "Organisation"), " gestellt."
                    , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 172}} ), "Sie können entweder über den Button „Jetzt starten“ schrittweise durch die Beantwortung der Fragen geleitet werden oder alternativ in der Übersicht (unten) mit einem spezifischen Handlungsfeld starten."




                  )
                  , React.createElement(Button, {
                    variant: "contained",
                    color: "primary",
                    size: "small",
                    onClick: () => {
                      navigate(
                        `/check/edit/category/${_optionalChain([app, 'access', _4 => _4.benchmarks, 'access', _5 => _5.categories, 'access', _6 => _6[0], 'optionalAccess', _7 => _7.objectId])}`
                      );
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
, "Jetzt starten"

                  )
                )
              ) : Object.keys(
                  app.benchmarks.benchmarks[app.benchmarks.currentBenchmark]
                    .answers
                ).length < app.benchmarks.questions.length ? (
                React.createElement(React.Fragment, null
                  , React.createElement(Typography, { variant: "body1", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 196}}, "Einige Fragen sind noch unbeantwortet."

                    , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 198}} ), "Starten Sie in der Übersicht unten gezielt in dem spezifischen Handlungsfeld, in dem noch offene Fragen zu beantworten sind."



                  )
                  , React.createElement(Button, {
                    variant: "text",
                    color: "primary",
                    size: "medium",
                    startIcon: React.createElement(ArrowDownwardIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 207}} ),
                    onClick: scrollToRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 203}}
, "Zu den Handlungsfeldern"

                  )
                )
              ) : (
                React.createElement(React.Fragment, null
                  , React.createElement(Typography, { variant: "body1", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 215}}, "Dieser KLW-Check ist vollständig beantwortet!"

                    , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 217}} ), "Sehen Sie sich die Auswertung an oder springen Sie in der Übersicht unten gezielt zu spezifischen Handlungsfeldern, um Ihre Antworten zu überarbeiten."



                  )
                  , !validation.valid &&
                    validation.field === "meta" &&
                    !app.parse.user && (
                      React.createElement(Typography, {
                        variant: "body1",
                        className: classes.validationMessage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 225}}
, "Bitte füllen die Informationen über Ihr Unternhemen aus, bevor Sie das Benchmark abschließen."


                      )
                    )
                  , React.createElement(Button, {
                    variant: "contained",
                    color: "primary",
                    disabled: !validation.valid,
                    onClick: () => {
                      app.benchmarks.finishCurrentBenchmark();
                      navigate("/check/edit/evaluation");
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 233}}
, "Zur Auswertung"

                  )
                )
              )
            )
          )
        )

        , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 250}}
          , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 251}}
            , React.createElement(Typography, { variant: "body1", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 252}}, "Binden Sie Kolleginnen und Kollegen beim Ausfüllen des Kreislaufwirtschafts-Check gerne mit ein. Senden Sie Ihnen dazu einfach diesen Link:"



            )
            , React.createElement(TextField, {
              value: app.benchmarks.benchmarkUrl,
              label: "Kreislaufwirtschafts-Check teilen" ,
              variant: "outlined",
              fullWidth: true,
              onFocus: (e) => {
                e.target.select();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 257}}
            )
          )
        )

        , React.createElement(Grid, { ref: sectionRef, item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 269}}
          , React.createElement(Typography, {
            variant: "h5",
            component: "h1",
            className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 270}}

            , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 275}}, "Die Dimensionen und Handlungsfelder im Überblick"     )
          )
        )

        , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 279}}
          , React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 280}}
            , app.benchmarks.dimensions.map((dimension) => (
              React.createElement(Grid, { key: dimension.objectId, item: true, xs: 12, sm: 4, __self: this, __source: {fileName: _jsxFileName, lineNumber: 282}}
                , React.createElement(Card, { style: { height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 283}}
                  , React.createElement(CardContent, { style: { height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 284}}
                    , React.createElement(Typography, {
                      style: { color: theme.palette.primary.main },
                      gutterBottom: true,
                      variant: "h5",
                      component: "h2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 285}}

                      , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 291}}, dimension.name)
                    )

                    , React.createElement(Typography, {
                      className: `${classes.content} ${
                        expandedDimensions[dimension.objectId]
                          ? classes.expanded
                          : classes.collapsed
                      }`,
                      gutterBottom: true,
                      variant: "body1",
                      component: "h2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 294}}

                      , expandedDimensions[dimension.objectId]
                        ? dimension.description
                        : dimension.description.slice(0, 150)
                      , React.createElement(Button, {
                        variant: "text",
                        color: "primary",
                        onClick: () => handleSeeMore(dimension.objectId), __self: this, __source: {fileName: _jsxFileName, lineNumber: 307}}

                        , expandedDimensions[dimension.objectId]
                          ? "Weniger anzeigen"
                          : "... Mehr anzeigen"
                      )
                    )

                    , React.createElement(Grid, { container: true, spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 318}}
                      , app.benchmarks.categories
                        .filter(
                          (category) =>
                            _optionalChain([category, 'access', _8 => _8.dimension, 'optionalAccess', _9 => _9.objectId]) === dimension.objectId
                        )
                        .map((category) => {
                          const questions = app.benchmarks.questions.filter(
                            (q) => _optionalChain([q, 'access', _10 => _10.category, 'optionalAccess', _11 => _11.objectId]) === category.objectId
                          );

                          const questionIds = questions.map((q) => q.objectId);

                          const answers = Object.entries(
                            app.benchmarks.answers
                          ).filter(
                            ([questionId, value]) =>
                              value && questionIds.includes(questionId)
                          );

                          return (
                            React.createElement(Grid, { key: category.objectId, item: true, xs: 12, sm: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 339}}
                              , React.createElement(Card, { style: { height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 340}}
                                , React.createElement(CardContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 341}}
                                  , React.createElement(Typography, {
                                    gutterBottom: true,
                                    variant: "h6",
                                    component: "h2",
                                    style: {
                                      height: "3rem",
                                      marginBottom: "0.75rem",
                                      color: theme.palette.primary.main,
                                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 342}}

                                    , category.name
                                    , React.createElement(CustomTooltip, {
                                      className: classes.toolTip,
                                      title: category.description,
                                      placement: "right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 353}}

                                      , React.createElement(IconButton, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 358}}
                                        , React.createElement(InfoIcon, {
                                          fontSize: "small",
                                          color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 359}}
                                        )
                                      )
                                    )
                                  )
                                  , React.createElement(CustomLinearProgress, {
                                    variant: "determinate",
                                    value: 
                                      (answers.length / questions.length) * 100
                                    ,
                                    style: {
                                      marginTop: "0.3rem",
                                      marginBottom: "0.5rem",
                                      height: "0.35rem",
                                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 366}}
                                  )
                                  , React.createElement(Typography, {
                                    variant: "body1",
                                    color: "textSecondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 377}}

                                    , answers.length, " / "  , questions.length, " Fragen beantwortet"

                                  )
                                )
                                , React.createElement(CardActions, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 385}}
                                  , React.createElement(Button, {
                                    style: { marginTop: 0, paddingTop: 0 },
                                    color: "primary",
                                    size: "medium",
                                    onClick: () => {
                                      navigate(
                                        `/check/edit/category/${category.objectId}`
                                      );
                                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 386}}

                                    , answers.length === 0
                                      ? "Starten"
                                      : answers.length === questions.length
                                      ? "Überarbeiten"
                                      : "Fortsetzen"
                                  )
                                )
                              )
                            )
                          );
                        })
                    )
                  )
                )
              )
            ))
          )
        )

        /* <Grid item xs={12} className={classes.marginBottom}>
          <Grid container justify="center" spacing={2}>
            {app.benchmarks.categories.map((category) => {
              const questions = app.benchmarks.questions.filter(
                (q) => q.category?.objectId === category.objectId
              );

              const questionIds = questions.map((q) => q.objectId);

              const answers = Object.entries(app.benchmarks.answers).filter(
                ([questionId, value]) =>
                  value && questionIds.includes(questionId)
              );

              return (
                <Grid key={category.objectId} item xs={12} sm={4}>
                  <Card style={{ height: "100%" }}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {category.name}
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={(answers.length / questions.length) * 100}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {answers.length} / {questions.length} Fragen beantwortet
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => {
                          navigate(
                            `/benchmark/edit/category/${category.objectId}`
                          );
                        }}
                      >
                        {answers.length === 0
                          ? "Starten"
                          : answers.length === questions.length
                          ? "Überarbeiten"
                          : "Fortsetzen"}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid> */

        /* <Grid item xs={12} className={classes.marginBottom}>
          <Paper className={classes.padding}>
            <Typography variant="body1">
              {app.benchmarks.metaAsText}{" "}
              <Button
                // variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  navigate("/benchmark/edit/meta");
                }}
                className={classes.editMetaButton}
              >
                Bearbeiten
              </Button>
            </Typography>
          </Paper>
        </Grid> */
      )
    );
  }
);
