const _jsxFileName = "/usr/src/app/app/js/components/BenchmarkEditEvaluationRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  withStyles,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import AnimatedProgressProvider from "../helper/AnimatedProgressProvider";
import { easeQuadInOut } from "d3-ease";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import InfoIcon from "@material-ui/icons/Info";
import { EvaluationChart } from "./EvaluationChart";

import { imgToBase64 } from "./ImageToBase64";

const useStyles = makeStyles((theme) => ({
  validationMessage: {
    border: `1px solid ${theme.palette.error.dark}`,
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.9em",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
  },
  padding: {
    padding: theme.spacing(2),
  },
  editMetaButton: {
    marginLeft: theme.spacing(1),
  },
  toolTip: {
    paddingLeft: "0.5rem",
    paddingTop: "0.5rem",
  },
  measureLinkList: {
    listStyleType: "none",
    paddingLeft: "1.5em",
    counterReset: "item",
    margin: 0,
    padding: 0,
    "& li": {
      counterIncrement: "item",
      position: "relative",
      marginBottom: "0.5rem",
      paddingLeft: "2em",
      "&::before": {
        content: "counter(item) '. '",
        color: "black",
        position: "absolute",
        left: 0,
        top: 0,
      },
      "& a": {
        color: "black",
        textDecoration: "none",
        "&:hover": {
          color: `${theme.palette.primary.main} !important`,
        },
        "&:visited": {
          color: "black",
        },
      },
      "&:hover::before": {
        color: `${theme.palette.primary.main} !important`,
      },
    },
  },
  content: {
    marginBottom: "1rem",
    overflow: "hidden",
    transition: "max-height 0.3s ease",
  },
  expanded: {
    maxHeight: "none",
  },
  collapsed: {
    maxHeight: "250px",
  },
}));





export const BenchmarkEditEvaluationRoute = observer(
  function BenchmarkEditEvaluationRoute() {
    const app = useApp();
    const classes = useStyles();
    const theme = useTheme();
    const chartRef = React.useRef(null);
    const barRef = React.useRef(null);
    const textRef = React.useRef(null);

    const [expandedDimensions, setExpandedDimensions] =
      useState({});
    const [avg, setAvg] = useState(0);

    const handleSeeMore = (objectId) => {
      setExpandedDimensions((prev) => ({
        ...prev,
        [objectId]: !prev[objectId],
      }));
    };

    //predefined
    const FACTOR_ARRAY_CATEGORY = [
      0.1, 0.1617, 0.1846, 0.1076, 0.1252, 0.1134, 0.1159, 0.0916,
    ];
    const FACTOR_ARRAY_DIMENSION = [
      0.2241, 0.3623, 0.4136, 0.4622, 0.5378, 0.3534, 0.3611, 0.2855,
    ];

    // const [dataOtherCompany, setDataOtherCompany] =
    //   useState<BenchmarkAttributes[]>();
    // const [dataOtherCompanySameBranch, setDataOtherCompanySameBranch] =
    //   useState<BenchmarkAttributes[]>();

    useEffect(() => {
      window.scrollTo(0, 0);

      const fetchData = async () => {
        try {
          // const otherCompanyData = await app.benchmarks.fetchAllBenchmark();
          // const otherCompanySameBranchData =
          //   await app.benchmarks.fetchAllBenchmarkSameBranch();
          const sameCompanyData = await app.benchmarks.fetchUserBenchmark();

          // setDataOtherCompany(otherCompanyData);
          // setDataOtherCompanySameBranch(otherCompanySameBranchData);

          const evaluatingBenchmarkSameCompany = _optionalChain([sameCompanyData
, 'optionalAccess', _ => _.filter, 'call', _2 => _2((item) => item.isFinished)
, 'access', _3 => _3.map, 'call', _4 => _4((benchmark) => {
              const data = labelsID.map((l) =>
                getCategoryResult(l, benchmark)
              );
              return endResult(FACTOR_ARRAY_CATEGORY, data);
            })]);

          const score =
            evaluatingBenchmarkSameCompany &&
            evaluatingBenchmarkSameCompany.length > 0
              ? evaluatingBenchmarkSameCompany.reduce(
                  (acc, num) => acc + num,
                  0
                ) / evaluatingBenchmarkSameCompany.length
              : 0;

          setAvg(parseFloat(score.toFixed(1)));
        } catch (error) {
          console.log("Error when trying to fetch data from database");
        }
      };

      fetchData();
    }, []);

    const evaluatingBenchmark =
      app.benchmarks.benchmarks[app.benchmarks.currentBenchmark];

    const labels = app.benchmarks.categories.map((category) => category.name);

    const findCategoryIdsByLabels = (labels) =>
      labels.map((label) => {
        const foundCategory = app.benchmarks.categories.find(
          (category) => category.name === label
        );
        return _optionalChain([foundCategory, 'optionalAccess', _5 => _5.objectId]);
      });

    const labelsID = findCategoryIdsByLabels(labels);

    const getCategoryResult = (
      c,
      benchmark
    ) => {
      let res = 0;
      let numberOfQuestions = 0;

      const answersArray = benchmark.answers;

      app.benchmarks.questions
        .filter((q) => _optionalChain([q, 'access', _6 => _6.category, 'optionalAccess', _7 => _7.objectId]) === c)
        .forEach((q) => {
          for (const key in answersArray) {
            if (key === q.objectId) {
              res += answersArray[key] - 1;
              numberOfQuestions += 1;
            }
          }
        });

      return numberOfQuestions > 0
        ? parseFloat((res / numberOfQuestions).toFixed(1))
        : 0;
    };

    const evaluation = labelsID.map((l) =>
      getCategoryResult(l, evaluatingBenchmark)
    );

    const getScoreFromEvaluation = (evaluationArray) => {
      const sum = evaluationArray.reduce(
        (acc, num) => acc + num,
        0
      );
      const average = sum / evaluationArray.length;
      return Math.floor(average);
    };

    const getEvaluationName = (score) => {
      switch (Math.floor(score)) {
        case 0:
          return "Reifegrad 0 (Passiv)";
        case 1:
          return "Reifegrad 1 (Entwicklend)";
        case 2:
          return "Reifegrad 2 (Systematisch)";
        case 3:
          return "Reifegrad 3 (Integriert)";
        case 4:
          return "Reifegrad 4 (Zirkulär)";
      }
    };

    const endResult = (factorArray, numberArray) => {
      if (factorArray.length !== numberArray.length) {
        throw new Error("Arrays must have the same length");
      }

      const result = factorArray.reduce(
        (acc, factor, index) =>
          acc + factor * numberArray[index],
        0
      );
      return parseFloat(result.toFixed(1));
    };

    const endResultDimension = (
      factorArray,
      numberArray
    ) => {
      if (factorArray.length !== numberArray.length) {
        throw new Error("Arrays must have the same length");
      }

      const result = factorArray.map(
        (factor, index) => factor * numberArray[index]
      );

      const sumProduct = parseFloat(
        (result[0] + result[1] + result[2]).toFixed(1)
      );

      const sumProcess = parseFloat((result[3] + result[4]).toFixed(1));

      const sumOrganisation = parseFloat(
        (result[5] + result[6] + result[7]).toFixed(1)
      );

      return [sumProduct, sumProcess, sumOrganisation];
    };

    // const evaluationOtherCompany = () => {
    //   if (dataOtherCompany) {
    //     const results = labelsID.map((l: string) => {
    //       let sum = 0;
    //       for (let b = 0; b < dataOtherCompany.length; b++) {
    //         sum += getCategoryResult(l, dataOtherCompany[b]);
    //       }
    //       return parseFloat((sum / dataOtherCompany.length).toFixed(1));
    //     });
    //     return results;
    //   }
    //   return [];
    // };

    // const evaluationOtherCompanySameBranch = () => {
    //   if (dataOtherCompanySameBranch) {
    //     const results = labelsID.map((l: string) => {
    //       let sum = 0;
    //       for (let b = 0; b < dataOtherCompanySameBranch.length; b++) {
    //         sum += getCategoryResult(l, dataOtherCompanySameBranch[b]);
    //       }
    //       return parseFloat(
    //         (sum / dataOtherCompanySameBranch.length).toFixed(1)
    //       );
    //     });
    //     return results;
    //   }
    //   return [];
    // };

    // const [evaluationCheck, setEvaluationCheck] = React.useState({
    //   checkedOtherCompany: false,
    //   checkedSameBranch: false,
    // });

    // const handleCheckboxChange = (
    //   event: React.ChangeEvent<HTMLInputElement>
    // ) => {
    //   setEvaluationCheck({
    //     ...evaluationCheck,
    //     [event.target.name]: event.target.checked,
    //   });
    // };

    const CustomTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(15),
        border: `5px solid ${theme.palette.primary.main}`,
      },
    }))(Tooltip);

    const handleDownloadPdf = async () => {
      const chart = chartRef.current;
      const bar = barRef.current;
      const text = textRef.current;

      if (!chart || !bar || !text) {
        return;
      }

      const chartCanvas = await html2canvas(chart);
      const barCanvas = await html2canvas(bar);
      const textCanvas = await html2canvas(text);

      const chartData = chartCanvas.toDataURL("image/png");
      const barData = barCanvas.toDataURL("image/png");
      const textData = textCanvas.toDataURL("image/png");

      const pdf = new jsPDF();

      const chartImgProperties = pdf.getImageProperties(chartData);
      const barImgProperties = pdf.getImageProperties(barData);
      const textImgProperties = pdf.getImageProperties(textData);

      const pdfWidth = pdf.internal.pageSize.getWidth();

      const chartHeight =
        (chartImgProperties.height * pdfWidth) / chartImgProperties.width;
      const barHeight =
        (barImgProperties.height * pdfWidth) / barImgProperties.width;
      const textHeight =
        (textImgProperties.height * pdfWidth) / textImgProperties.width;

      const today = new Date();
      const day = today.getDate();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();

      const formattedDate = `${day < 10 ? "0" + day : day}/${
        month < 10 ? "0" + month : month
      }/${year}`;

      pdf.setFont("arial", "bold");
      pdf.setFontSize(20);
      pdf.addImage(imgToBase64.logo_darmstart, 10, 10, 40, 10);
      pdf.addImage(imgToBase64.logo_wnw, 55, 10, 40, 10);
      pdf.text("Kreislaufwirtschafts-Check Report", 10, 40);
      pdf.setFont("arial", "normal");
      pdf.setFontSize(10);
      if (app.parse.user) {
        pdf.text(
          `Resultate des Kreislaufwirtschafts-Checks, ${
            app.benchmarks.benchmarks[app.benchmarks.currentBenchmark].alt_name
              ? app.benchmarks.benchmarks[app.benchmarks.currentBenchmark]
                  .alt_name
              : ""
          }`,
          10,
          50
        );
        pdf.text(
          `durchgeführt am ${formattedDate} durch ${
            app.benchmarks.benchmarks[app.benchmarks.currentBenchmark]
              .contact_name
          } ${
            app.benchmarks.benchmarks[app.benchmarks.currentBenchmark]
              .contact_family_name
          } für das Unternehmen ${app.parse.user.companyName}`,
          10,
          55
        );
      } else {
        pdf.text(
          `Resultate des Kreislaufwirtschafts-Checks, durchgeführt am ${formattedDate}`,
          10,
          50
        );
      }

      pdf.addImage(textData, "PNG", 10, 80, pdfWidth, textHeight);
      pdf.addImage(
        chartData,
        "PNG",
        10,
        120,
        pdfWidth * 0.9,
        chartHeight * 0.9
      );
      pdf.addPage("a3", "portrait");
      pdf.addImage(barData, "PNG", 34, 35, pdfWidth * 1.1, barHeight * 1.1);
      pdf.setFillColor("white");

      pdf.save("report.pdf");
    };

    return (
      React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 439}}
        , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 440}}
          , React.createElement(Typography, {
            variant: "h4",
            component: "h1",
            className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 441}}

            , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 446}}, "Auswertung"
              , " "
              , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 448}}
                , app.benchmarks.benchmarks[app.benchmarks.currentBenchmark]
                  .alt_name
                  ? ` ${
                      app.benchmarks.benchmarks[
                        app.benchmarks.currentBenchmark
                      ].alt_name
                    }`
                  : null
              )
            )
          )
        )

        , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 462}}
          , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 463}}
            , React.createElement(Typography, {
              style: { color: theme.palette.primary.main },
              gutterBottom: true,
              variant: "h5",
              component: "h2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 464}}

              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 470}}, "Meine Reifegrade im Überblick"   )
            )

            , React.createElement(Grid, { container: true, direction: "row", __self: this, __source: {fileName: _jsxFileName, lineNumber: 473}}
              , React.createElement(Grid, {
                item: true,
                xs: 12,
                style: {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 474}}

                , React.createElement('div', {
                  style: {
                    height: "100%",
                    width: "100%",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 483}}

                  , React.createElement(Typography, {
                    ref: textRef,
                    variant: "body1",
                    style: { paddingBottom: "2rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 489}}
, "Sie haben über alle Handlungsfelder hinweg einen durchschnittlichen Reifegrad von"

                      , " "
                    , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 496}}
                      , app.parse.user
                        ? avg
                        : endResult(FACTOR_ARRAY_CATEGORY, evaluation), " "
                    ), "erreicht. Insgesamt entspricht dies dem"
                        , " "
                    , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 502}}
                      , app.parse.user
                        ? getEvaluationName(avg)
                        : getEvaluationName(getScoreFromEvaluation(evaluation))
                    ), "."

                    , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 508}} ), "Untenstehend werden die erreichten Reifegrade pro Handlungsfeld nochmals angezeigt."


                  )

                  , React.createElement('div', {
                    ref: chartRef,
                    style: {
                      height: 500,
                      width: 1000,
                      margin: "0 auto",
                      paddingTop: "1rem",
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 513}}

                    , React.createElement(EvaluationChart, {
                      labels: labels,
                      evaluation: evaluation, __self: this, __source: {fileName: _jsxFileName, lineNumber: 522}}
                      // checkedOtherCompany={evaluationCheck.checkedOtherCompany}
                      // checkedSameBranch={evaluationCheck.checkedSameBranch}
                      // evaluationOtherCompany={evaluationOtherCompany()}
                      // evaluationSameBranch={evaluationOtherCompanySameBranch()}
                    )
                  )
                )
              )

              /* <Grid
                item
                xs={12}
                sm={5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={evaluationCheck.checkedOtherCompany}
                        onChange={handleCheckboxChange}
                        name="checkedOtherCompany"
                        color="primary"
                      />
                    }
                    label="Zeige andere Unternehmen an"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={evaluationCheck.checkedSameBranch}
                        onChange={handleCheckboxChange}
                        name="checkedSameBranch"
                        color="primary"
                      />
                    }
                    label="Zeige andere Unternehmen in meiner Branche an"
                  />
                </FormGroup>
              </Grid> */
            )
          )
        )

        , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 573}}
          , React.createElement(Grid, { ref: barRef, container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 574}}
            , app.benchmarks.dimensions.map((dimension, index) => (
              React.createElement(Grid, { key: dimension.objectId, item: true, xs: 12, sm: 4, __self: this, __source: {fileName: _jsxFileName, lineNumber: 576}}
                , React.createElement(Card, { style: { height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 577}}
                  , React.createElement(CardContent, { style: { height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 578}}
                    , React.createElement(Typography, {
                      style: { color: theme.palette.primary.main },
                      gutterBottom: true,
                      variant: "h5",
                      component: "h2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 579}}

                      , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 585}}, dimension.name)
                    )

                    , React.createElement(Typography, {
                      className: `${classes.content} ${
                        expandedDimensions[dimension.objectId]
                          ? classes.expanded
                          : classes.collapsed
                      }`,
                      gutterBottom: true,
                      variant: "body1",
                      component: "h2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 588}}

                      , expandedDimensions[dimension.objectId]
                        ? dimension.description
                        : dimension.description.slice(0, 150)
                      , React.createElement(Button, {
                        variant: "text",
                        color: "primary",
                        onClick: () => handleSeeMore(dimension.objectId), __self: this, __source: {fileName: _jsxFileName, lineNumber: 601}}

                        , expandedDimensions[dimension.objectId]
                          ? "Weniger anzeigen"
                          : "... Mehr anzeigen"
                      )
                    )

                    , React.createElement(Typography, {
                      style: { marginBottom: "4rem", height: "2rem" },
                      gutterBottom: true,
                      variant: "body1",
                      component: "h2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 612}}
, "Der durchschnittliche Reifegrad für die Dimension"
                           , " "
                      , dimension.name, " ist:" , " "
                      , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 620}}
                        , 
                          endResultDimension(
                            FACTOR_ARRAY_DIMENSION,
                            evaluation
                          )[index]
                        
                      )
                    )

                    , React.createElement(Grid, { container: true, spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 630}}
                      , app.benchmarks.categories
                        .filter(
                          (category) =>
                            _optionalChain([category, 'access', _8 => _8.dimension, 'optionalAccess', _9 => _9.objectId]) === dimension.objectId
                        )
                        .map((category) => {
                          const questions = app.benchmarks.questions.filter(
                            (q) => _optionalChain([q, 'access', _10 => _10.category, 'optionalAccess', _11 => _11.objectId]) === category.objectId
                          );

                          const questionIds = questions.map((q) => q.objectId);

                          const answers = Object.entries(
                            app.benchmarks.answers
                          ).filter(
                            ([questionId, value]) =>
                              value && questionIds.includes(questionId)
                          );

                          return (
                            React.createElement(Grid, {
                              key: category.objectId,
                              item: true,
                              xs: 12,
                              sm: 12,
                              className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 651}}

                              , React.createElement(Card, { style: { height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 658}}
                                , React.createElement(CardContent, {
                                  style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 659}}

                                  , React.createElement(Typography, {
                                    gutterBottom: true,
                                    variant: "h6",
                                    component: "h2",
                                    style: {
                                      height: "3rem",
                                      marginBottom: "0.75rem",
                                      color: theme.palette.primary.main,
                                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 666}}

                                    , category.name
                                    , React.createElement(CustomTooltip, {
                                      className: classes.toolTip,
                                      title: category.description,
                                      placement: "right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 677}}

                                      , React.createElement(IconButton, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 682}}
                                        , React.createElement(InfoIcon, {
                                          fontSize: "small",
                                          color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 683}}
                                        )
                                      )
                                    )
                                  )

                                  , React.createElement(AnimatedProgressProvider, {
                                    valueStart: 0,
                                    valueEnd: 
                                      ((getCategoryResult(
                                        category.objectId,
                                        evaluatingBenchmark
                                      ) +
                                        1) /
                                        5) *
                                      100
                                    ,
                                    duration: 3,
                                    easingFunction: easeQuadInOut, __self: this, __source: {fileName: _jsxFileName, lineNumber: 691}}

                                    , (value) => {
                                      return (
                                        React.createElement(Grid, {
                                          style: {
                                            justifySelf: "center",
                                            alignSelf: "center",
                                            width: "60%",
                                            height: "60%",
                                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 707}}

                                          , React.createElement(CircularProgressbarWithChildren, {
                                            value: value,
                                            counterClockwise: true,
                                            styles: buildStyles({
                                              pathTransition: "none",
                                              pathColor:
                                                theme.palette.primary.main,
                                              trailColor: "#d6d6d6",
                                            }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 715}}

                                            , React.createElement('p', {
                                              style: {
                                                fontSize: 13,
                                                marginTop: 10,
                                              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 725}}
, "Erreichter Reifegrad"

                                            )
                                            , React.createElement('h1', {
                                              style: {
                                                fontSize: 50,
                                                color:
                                                  theme.palette.primary.main,
                                                padding: 0,
                                                margin: 0,
                                              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 733}}

                                              , parseFloat(
                                                getCategoryResult(
                                                  category.objectId,
                                                  evaluatingBenchmark
                                                ).toFixed(1)
                                              )
                                            )
                                          )
                                        )
                                      );
                                    }
                                  )

                                  , React.createElement(Typography, { style: { marginTop: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 755}}, "Maßnahmen, um einen höheren Reifegrad zu erreichen:"


                                  )
                                  , React.createElement(Typography, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 759}}
                                    , React.createElement('ol', { className: classes.measureLinkList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 760}}
                                      , app.benchmarks.measures
                                        .filter(
                                          (measure) =>
                                            measure.category.objectId ===
                                            category.objectId
                                        )
                                        .map((measure) => (
                                          React.createElement('li', { key: measure.objectId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 768}}
                                            , React.createElement(Link, {
                                              to: `/measure/${measure.objectId}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 769}}

                                              , measure.name
                                            )
                                          )
                                        ))
                                    )
                                  )
                                )
                              )
                            )
                          );
                        })
                    )
                  )
                )
              )
            ))
          )
        )
        , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 790}}
          , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 791}}
            , React.createElement(Typography, { variant: "body1", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 792}}, "Teilen Sie Ihre Resultate mit dem untenstehenden Link:"

            )
            , React.createElement(TextField, {
              value: app.benchmarks.benchmarkResultUrl,
              label: "Benchmark teilen" ,
              variant: "outlined",
              fullWidth: true,
              onFocus: (e) => {
                e.target.select();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 795}}
            )
          )
        )
        , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 806}}
          , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 807}}
            , React.createElement(Typography, { variant: "body1", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 808}}, "Laden Sie Ihre Resultate als PDF herunter"

            )
            , React.createElement(Button, {
              variant: "contained",
              color: "primary",
              size: "small",
              onClick: handleDownloadPdf, __self: this, __source: {fileName: _jsxFileName, lineNumber: 811}}
, "Download"

            )
          )
        )
      )
    );
  }
);
