const _jsxFileName = "/usr/src/app/app/js/components/MeasureRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import {
  Grid,
  makeStyles,
  Typography,
  Card,
  CardContent,

} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
  },
  accordionSummary: {
    height: "2.5rem",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
}));

export const MeasureRoute = observer(function MeasureRoute() {
  const app = useApp();
  const classes = useStyles();
  const theme = useTheme();
  const { measureId } = useParams();
  const accordionRefs = useRef({});

  useEffect(() => {
    if (measureId) {
      const measureRef = accordionRefs.current[measureId];
      if (measureRef) {
        measureRef.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [measureId]);

  return (
    React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
      , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
        , React.createElement(Typography, {
          variant: "h4",
          component: "h1",
          className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}

          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}, "Maßnahmen für mehr Kreislaufwirtschaft"   )
        )
      )

      , React.createElement(Grid, { item: true, container: true, xs: 12, className: classes.marginBottom, spacing: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        , React.createElement(Grid, { container: true, spacing: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
          , React.createElement(Grid, { item: true, md: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
            , React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
              , app.benchmarks.dimensions.map((dimension) => (
                React.createElement(Grid, { key: dimension.objectId, item: true, md: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
                  , React.createElement(Card, { style: { height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
                    , React.createElement(CardContent, { style: { height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
                      , React.createElement(Typography, {
                        style: { color: theme.palette.primary.main },
                        gutterBottom: true,
                        variant: "h5",
                        component: "h2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}

                        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}, dimension.name)
                      )

                      , React.createElement(Grid, { container: true, spacing: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
                        , app.benchmarks.categories
                          .filter(
                            (category) =>
                              _optionalChain([category, 'access', _ => _.dimension, 'optionalAccess', _2 => _2.objectId]) ===
                              dimension.objectId
                          )
                          .map((category) => {
                            return (
                              React.createElement(Grid, { key: category.objectId, item: true, xs: 12, md: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
                                , React.createElement(Typography, {
                                  gutterBottom: true,
                                  variant: "h6",
                                  component: "h2",
                                  style: {
                                    height: "2rem",
                                    marginBottom: "0.5rem",
                                    color: theme.palette.primary.main,
                                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}

                                  , category.name
                                )

                                , app.benchmarks.measures
                                  .filter(
                                    (measure) =>
                                      measure.category.objectId ===
                                      category.objectId
                                  )
                                  .map((measure) => (
                                    React.createElement(Accordion, {
                                      key: measure.objectId,
                                      defaultExpanded: 
                                        measure.objectId === measureId
                                      ,
                                      ref: (el) =>
                                        (accordionRefs.current[
                                          measure.objectId
                                        ] = el)
                                      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}

                                      , React.createElement(AccordionSummary, {
                                        expandIcon: React.createElement(ExpandMoreIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}} ),
                                        'aria-controls': measure.objectId,
                                        id: measure.objectId,
                                        classes: {
                                          content: classes.accordionSummary,
                                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}

                                        , React.createElement(Typography, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
                                          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}, measure.name)
                                        )
                                      )
                                      , React.createElement(AccordionDetails, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
                                        , React.createElement(Typography, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
                                          , measure.description
                                        )
                                      )
                                    )
                                  ))
                              )
                            );
                          })
                      )
                    )
                  )
                )
              ))
            )
          )
        )
      )
    )
  );
});
