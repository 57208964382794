const _jsxFileName = "/usr/src/app/app/js/components/Layout.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useState } from "react";
import styled from "styled-components";

import { Link, useNavigate } from "react-router-dom";

import { Navigation } from "./Navigation";
import { AnimatedBackground } from "./AnimatedBackground";
import { FooterContent } from "./FooterContent";
import {
  MenuItem,
  Popover,
  IconButton,
  Drawer,
  ListItemText,
  List,
  ListItem,
  Divider,
  Collapse,
  makeStyles,
  createStyles,

} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useApp } from "../context";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const Header = styled.div`
  height: 10vh;
  background: white;
  border-bottom: 1px solid #ebebeb;
  user-select: none;
  overflow: hidden;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  padding: 0 10px;

  @media (max-width: 1080px) {
    height: auto;
    padding: 0 15px;
    display: block;
  }
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 90px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
`;

const LogoHolder = styled.div`
  display: block;
  float: left;
  height: 90px;
  margin-right: 15px;

  img {
    object-fit: contain;
    height: 90px;
    width: 120px;
  }
`;

const HeaderTitle = styled.div`
  display: block;
  float: left;

  height: 90px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;

  padding: 20px 0;

  a {
    display: inline-block;
    height: 50px;
    line-height: 50px;

    border-left: 1px solid #eee;
    color: var(--color-default-font);
    text-decoration: none;

    padding: 0 15px;

    &:hover {
      color: var(--color-primary);
    }
  }

  @media (max-width: 1080px) {
    display: none;
  }
`;

const HeaderNav = styled.nav`
  display: block;
  float: right;
  margin-left: auto;

  height: 90px;
  line-height: 90px;

  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;

  a {
    display: inline-block;
    height: 90px;

    color: var(--color-default-font);
    text-decoration: none;

    padding: 0 15px;

    &:hover {
      color: var(--color-primary);
    }
  }

  @media (max-width: 1080px) {
    display: none;
  }
`;

const BurgerMenu = styled.div`
  display: none;
  @media (max-width: 1080px) {
    display: block;
    heigth: 120px;
    width: 90px;
    margin-left: auto;
  }
`;

const Content = styled.div`
  padding: 20px 0;

  min-height: calc(100vh - 90px - 90px);

  position: relative;
  z-index: 2;
`;

const AnimatedBackgroundContainer = styled.div`
  /* opacity: 0.1; */
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  > div {
    height: 100vh;
    width: 100vw;
  }

  z-index: 1;
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 300,
    },
    nested: {
      paddingLeft: theme.spacing(4),
      "&:hover .MuiListItemText-primary": {
        color: theme.palette.primary.main,
      },
    },
    listItem: {
      "&:hover .MuiListItemText-primary": {
        color: theme.palette.primary.main,
      },
    },
    iconButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
    },
  })
);

export const Layout = React.memo(function Layout({ children }) {
  const app = useApp();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const [expand, setExpand] = React.useState(false);

  const handleExpand = () => {
    setExpand(!expand);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    console.log(isDrawerOpen);
    setDrawerOpen(open);
    console.log(isDrawerOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleJump = (para) => {
    handleClose();
    navigate(`/info/${para}`);
    setDrawerOpen(false);
  };

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Header, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 246}}
        , React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 247}}
          , React.createElement(HeaderInner, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 248}}
            , React.createElement(LogoHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 249}}
              , React.createElement('a', { href: "https://digitalzentrum-darmstadt.de/", target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 250}}
                , React.createElement('img', {
                  src: require("../assets/logo/mdz-darmstadt.png"),
                  alt: "Mittelstand-Digitalzentrum Darmstadt" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 251}}
                )
              )
            )
            , React.createElement(LogoHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 257}}
              , React.createElement('a', {
                href: "https://www.mittelstand-digital-wertnetzwerke.de/",
                target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 258}}

                , React.createElement('img', {
                  src: require("../assets/logo/mdz-wnw.png"),
                  alt: "Mittelstand-Digitalzentrum WertNetZWerke" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 262}}
                )
              )
            )
            , React.createElement(HeaderTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 268}}
              , React.createElement(Link, { to: "check", __self: this, __source: {fileName: _jsxFileName, lineNumber: 269}}, "Startseite")
              , React.createElement(Link, {
                'aria-controls': "simple-menu",
                'aria-haspopup': "true",
                onClick: handleClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 270}}
, "Mehr erfahren"

              )

              , React.createElement(Popover, {
                open: open,
                anchorEl: anchorEl,
                onClose: handleClose,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 278}}

                , React.createElement(MenuItem, { onClick: () => handleJump(1), __self: this, __source: {fileName: _jsxFileName, lineNumber: 291}}, "Was ist Kreislaufwirtschaft?"

                )
                , React.createElement(MenuItem, { onClick: () => handleJump(2), __self: this, __source: {fileName: _jsxFileName, lineNumber: 294}}, "Das Projekt" )
                , React.createElement(MenuItem, { onClick: () => handleJump(3), __self: this, __source: {fileName: _jsxFileName, lineNumber: 295}}, "Der KLW-Check" )
                , React.createElement(MenuItem, { onClick: () => handleJump(4), __self: this, __source: {fileName: _jsxFileName, lineNumber: 296}}, "Das Reifegradmodell"

                )
              )
              , React.createElement(Link, { to: "measure", __self: this, __source: {fileName: _jsxFileName, lineNumber: 300}}, "Maßnahmenkatalog")
              , React.createElement(Link, { to: "contact", __self: this, __source: {fileName: _jsxFileName, lineNumber: 301}}, "Kontakt")
            )
            , React.createElement(HeaderNav, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 303}}
              , React.createElement(Navigation, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 304}} )
            )

            , React.createElement(BurgerMenu, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 307}}
              , React.createElement(IconButton, {
                'aria-label': "menu",
                onClick: toggleDrawer(true),
                className: classes.iconButton,
                component: "span", __self: this, __source: {fileName: _jsxFileName, lineNumber: 308}}

                , React.createElement(MenuIcon, { fontSize: "large", __self: this, __source: {fileName: _jsxFileName, lineNumber: 314}} )
              )
              , React.createElement(Drawer, {
                anchor: "right",
                open: isDrawerOpen,
                onClose: toggleDrawer(false),
                classes: {
                  paper: classes.root,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 316}}

                , React.createElement(List, { className: classes.root, __self: this, __source: {fileName: _jsxFileName, lineNumber: 324}}
                  , React.createElement(ListItem, {
                    button: true,
                    component: Link,
                    to: "check",
                    onClick: toggleDrawer(false),
                    className: classes.listItem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 325}}

                    , React.createElement(ListItemText, { primary: "Startseite", __self: this, __source: {fileName: _jsxFileName, lineNumber: 332}} )
                  )
                  , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 334}} )
                  , React.createElement(ListItem, {
                    button: true,
                    onClick: handleExpand,
                    className: classes.listItem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 335}}

                    , React.createElement(ListItemText, { primary: "Mehr erfahren" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 340}} )

                    , expand ? React.createElement(ExpandLess, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 342}} ) : React.createElement(ExpandMore, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 342}} )
                  )
                  , React.createElement(Collapse, { in: expand, timeout: "auto", unmountOnExit: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 344}}
                    , React.createElement(List, { component: "div", disablePadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 345}}
                      , React.createElement(ListItem, {
                        button: true,
                        className: classes.nested,
                        onClick: () => {
                          handleJump(1);
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 346}}

                        , React.createElement(ListItemText, { primary: "Was ist Kreislaufswirtschaft?"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 353}} )
                      )
                      , React.createElement(ListItem, {
                        button: true,
                        className: classes.nested,
                        onClick: () => {
                          handleJump(2);
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 355}}

                        , React.createElement(ListItemText, { primary: "Das Projekt" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 362}} )
                      )
                      , React.createElement(ListItem, {
                        button: true,
                        className: classes.nested,
                        onClick: () => {
                          handleJump(3);
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 364}}

                        , React.createElement(ListItemText, { primary: "Der KLW-Check" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 371}} )
                      )
                      , React.createElement(ListItem, {
                        button: true,
                        className: classes.nested,
                        onClick: () => {
                          handleJump(4);
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 373}}

                        , React.createElement(ListItemText, { primary: "Das Reifegradmodell" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 380}} )
                      )
                    )
                  )
                  , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 384}} )
                  , React.createElement(ListItem, {
                    button: true,
                    component: Link,
                    to: "measure",
                    onClick: toggleDrawer(false),
                    className: classes.listItem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 385}}

                    , React.createElement(ListItemText, { primary: "Maßnahmenkatalog", __self: this, __source: {fileName: _jsxFileName, lineNumber: 392}} )
                  )
                  , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 394}} )
                  , React.createElement(ListItem, {
                    button: true,
                    component: Link,
                    to: "contact",
                    onClick: toggleDrawer(false),
                    className: classes.listItem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 395}}

                    , React.createElement(ListItemText, { primary: "Kontakt", __self: this, __source: {fileName: _jsxFileName, lineNumber: 402}} )
                  )
                  , _optionalChain([app, 'access', _ => _.parse, 'access', _2 => _2.user, 'optionalAccess', _3 => _3.isAdmin]) && (
                    React.createElement(React.Fragment, null
                      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 406}} )
                      , React.createElement(ListItem, {
                        button: true,
                        component: Link,
                        to: "admin",
                        onClick: toggleDrawer(false),
                        className: classes.listItem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 407}}

                        , React.createElement(ListItemText, { primary: "Admin", __self: this, __source: {fileName: _jsxFileName, lineNumber: 414}} )
                      )
                      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 416}} )
                      , React.createElement(ListItem, {
                        button: true,
                        component: Link,
                        to: "logout",
                        onClick: toggleDrawer(false),
                        className: classes.listItem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 417}}

                        , React.createElement(ListItemText, { primary: "Abmelden", __self: this, __source: {fileName: _jsxFileName, lineNumber: 424}} )
                      )
                    )
                  )
                  , app.parse.user && (
                    React.createElement(React.Fragment, null
                      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 430}} )
                      , React.createElement(ListItem, {
                        button: true,
                        component: Link,
                        to: "check/summary",
                        onClick: toggleDrawer(false),
                        className: classes.listItem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 431}}

                        , React.createElement(ListItemText, { primary: "Historie", __self: this, __source: {fileName: _jsxFileName, lineNumber: 438}} )
                      )
                      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 440}} )
                      , React.createElement(ListItem, {
                        button: true,
                        component: Link,
                        to: "logout",
                        onClick: toggleDrawer(false),
                        className: classes.listItem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 441}}

                        , React.createElement(ListItemText, { primary: "Abmelden", __self: this, __source: {fileName: _jsxFileName, lineNumber: 448}} )
                      )
                    )
                  )
                  , !app.parse.user && (
                    React.createElement(React.Fragment, null
                      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 454}} )
                      , React.createElement(ListItem, {
                        button: true,
                        component: Link,
                        to: "userLogin",
                        onClick: toggleDrawer(false),
                        className: classes.listItem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 455}}

                        , React.createElement(ListItemText, { primary: "Anmelden", __self: this, __source: {fileName: _jsxFileName, lineNumber: 462}} )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )

      , React.createElement(Content, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 473}}
        , React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 474}}, children)
      )

      , React.createElement(FooterContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 477}} )

      , React.createElement(AnimatedBackgroundContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 479}}
        , React.createElement(AnimatedBackground, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 480}} )
      )
    )
  );
});
