const _jsxFileName = "/usr/src/app/app/js/components/BenchmarkEditCategoryQuestions.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { useSprings, animated } from "react-spring";

import AnimatedProgressProvider from "../helper/AnimatedProgressProvider";
import { easeQuadInOut } from "d3-ease";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTheme } from "@material-ui/core/styles";
import {
  Button,
  FormControlLabel,
  IconButton,
  makeStyles,
  withStyles,
  Tooltip,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import { Icon } from "@opendash/icons";
import InfoIcon from "@material-ui/icons/Info";
import KeyboardArrowLeftRoundedIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import FlagIcon from "@material-ui/icons/Flag";

import Steps from "rc-steps";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: 300,
    flexShrink: 0,
  },
  questionContainer: {
    height: "470px",
    minHeight: "50vh",
    position: "relative",
    padding: 0,
  },
  question: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  questionInner: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    alignSelf: "center",
    width: "100%",
    minHeight: 400,
  },
  questionContent: {
    width: "90%",
  },
  questionName: {
    fontSize: "22px",
    fontWeight: "bold",
    marginTop: theme.spacing(3),
  },
  questionDescription: {
    fontSize: "18px",
    marginBottom: theme.spacing(2),
    textAlign: "justify",
  },
  questionVideo: {
    marginBottom: theme.spacing(2),
    position: "relative",
    paddingTop: "56.25%",
  },
  questionVideoPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    marginBottom: theme.spacing(2),
  },
  questionAnswers: {
    "& *": {
      fontSize: "18px !important",
    },
  },
  questionActions: {
    marginTop: theme.spacing(2),
  },
  stepBar: {},
  stepStep: {
    overflow: "hidden",
  },
  categoryResult: {
    width: "100%",
    height: "100%",
    display: "grid",
    alignItems: "center",
    alignSelf: "center",
    gridTemplateColumns: "repeat(auto-fill, minmax(500px, 1fr))",
  },
  notFinished: {
    fontSize: "1rem",
    width: "100%",
    alignSelf: "center",
    justifySelf: "center",
    flexBasis: "auto",
  },
  chartResult: {
    height: "100%",
    width: "50%",
    display: "grid",
    alignItems: "center",
    justifySelf: "center",
    marginLeft: "5rem",
  },
  textResult: {
    display: "grid",
    marginLeft: "5rem",
    alignItems: "center",
  },
  radioGroup: {},
  radioGroupLabel: {
    display: "flex",
    marginBottom: theme.spacing(1),
    marginLeft: 0,
  },
  radioGroupRadio: {
    padding: 0,
    marginRight: 20,
  },
  toolTip: {
    paddingLeft: "0.5rem",
    paddingTop: "0.5rem",
  },
  navigationBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    width: "100%",
    height: "auto",
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  navigationArrow: {
    "&:hover": {
      backgroundColor: "primary",
    },
  },
}));

export const BenchmarkEditCategoryQuestions = observer(
  function BenchmarkEditCategoryQuestions({
    category,
    questions,
    onQuestionAnswered,
  }



) {
    const app = useApp();
    const classes = useStyles();
    const theme = useTheme();

    const [index, setIndex] = useState(0);
    const [answerResults, setAnswerResults] = useState([]);
    const [categoryIsFinished, setCategoryIsFinished] =
      useState(false);

    useEffect(() => {
      const res = questions.map(
        (question) => app.benchmarks.answers[question.objectId]
      );

      const isFinished = !res.map((value) => !!value).includes(false);

      setAnswerResults(res);
      setCategoryIsFinished(isFinished);
    }, [questions]);

    useEffect(() => {
      window.scrollTo(0, 100);
    }, []);

    useEffect(() => {
      onQuestionAnswered(categoryIsFinished, index);
    }, [categoryIsFinished, onQuestionAnswered, index]);

    const changeQuestion = (questionIndex) => {
      setIndex(questionIndex);
      // @ts-ignore
      set((i) => {
        return { x: (i - questionIndex) * window.innerWidth };
      });
    };

    const answerResult = Math.floor(
      answerResults.reduce((acc, v) => acc + v, 0) / answerResults.length - 1
    ); //minus -1 to pass the falsy value check for completeness, since it starts with 1

    const maturityLevel = [
      "Passiv",
      "Entwickelnd",
      "Systematisch",
      "Integriert",
      "Zirkulär",
    ];

    const CustomTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(15),
        border: `5px solid ${theme.palette.primary.main}`,
      },
    }))(Tooltip);

    // could also be in report_text_1 to 4 but only appears once so its fine
    const maturityText = [
      "Im Reifegrad 'Passiv' existieren keine konkreten Projekte zur Integration von Kreislaufwirtschaft im Unternehmen. Es liegt kein Verständnis von zirkulärem Wirtschaften vor und das Thema ist nicht im Unternehmen integriert.",
      "Im Reifegrad 'Entwicklend' liegen einzelne Bemühungen zur Steigerung der Zirkularität vor, allerdings sind diese nicht vernetzt und systematisch. Es wird in einzelnen Unternehmensbereichen am Thema gearbeitet.",
      "Im Reifegrad 'Systematisch' existieren konkrete Maßnahmen zur Steigerung der Zirkularität, welche im Unternehmen systematisch umgesetzt werden. Stakeholder werden teilweise mit einbezogen und das Konzept der Kreislaufwirtschaft wird von diesen verstanden.",
      "Im Reifegrad 'Integriert' existieren konkrete ganzheitliche Maßnahmen gemeinsam im Netzwerk mit Partnern und Stakeholdern zur Steigerung der Zirkularität. Zirkuläres Wirtschaften ist tief in das Unternehmensdenken integriert.",
      "Der Reifegrad 'Zirkulär' entspricht einem Unternehmen, welches ganzheitlich auf ein zirkuläres Wirtschaften ausgelegt ist. Kreislaufwirtschaft ist die Basis des Unternehmens und des Unternehmenserfolgs. Im Netzwerk und mit allen Stakeholdern werden Aktivitäten im Sinne der Kreislaufwirtschaft weiter verbessert.",
    ];

    const slides = [
      React.createElement('div', { className: classes.questionInner, __self: this, __source: {fileName: _jsxFileName, lineNumber: 241}}
        , React.createElement('div', { className: classes.questionContent, __self: this, __source: {fileName: _jsxFileName, lineNumber: 242}}
          , React.createElement('div', { className: classes.questionName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 243}}, category.name)
          , React.createElement('div', { className: classes.questionDescription, __self: this, __source: {fileName: _jsxFileName, lineNumber: 244}}
            , React.createElement(ReactMarkdown, { children: category.description || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 245}} )
          )
          , React.createElement(Typography, { variant: "body2", style: { marginTop: "2rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 247}}, "Es kann immer nur eine Antwort ausgewählt werden. "
                    , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 248}} ), "Bitte wählen Sie die Antwort aus, die auf Ihr Unternehmen zutrifft!"

            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 250}} ), "Die Antworten stehen für die Reifegrade: Die oberste Antwort beschreibt den niedrigsten Grad, die unterste Antwort den höchsten Grad."



          )
          , React.createElement('div', { className: classes.questionActions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 255}}
            , React.createElement(Button, {
              variant: "contained",
              color: "primary",
              onClick: () => {
                changeQuestion(1);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 256}}
, "Zur ersten Frage"

            )
          )
        )
      ),
      ...questions.map((question, questionIndex) => {
        const currentValue =
          _optionalChain([app, 'access', _ => _.benchmarks, 'access', _2 => _2.answers, 'access', _3 => _3[question.objectId], 'optionalAccess', _4 => _4.toString, 'call', _5 => _5()]) || null;
        return (
          React.createElement('div', { className: classes.questionInner, __self: this, __source: {fileName: _jsxFileName, lineNumber: 272}}
            , React.createElement('div', { className: classes.questionContent, __self: this, __source: {fileName: _jsxFileName, lineNumber: 273}}
              , React.createElement('div', { className: classes.questionName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 274}}
                , question.name
                , !question.name_tooltip ? (
                  ""
                ) : (
                  React.createElement(CustomTooltip, {
                    className: classes.toolTip,
                    title: question.name_tooltip,
                    placement: "right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 279}}

                    , React.createElement(IconButton, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 284}}
                      , React.createElement(InfoIcon, { fontSize: "small", color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 285}} )
                    )
                  )
                )
              )

              , React.createElement('div', { className: classes.questionDescription, __self: this, __source: {fileName: _jsxFileName, lineNumber: 291}}
                , question.description
              )

              , React.createElement('div', { className: classes.questionAnswers, __self: this, __source: {fileName: _jsxFileName, lineNumber: 295}}
                , React.createElement(RadioGroup, {
                  row: false,
                  className: classes.radioGroup,
                  'aria-label': question.name,
                  name: question.objectId,
                  value: currentValue,
                  onChange: (e) => {
                    app.benchmarks
                      .updateAnswerCurrentBenchmark(
                        question.objectId,
                        parseInt(e.target.value) 
                      )
                      .then(() => {
                        changeQuestion(questionIndex + 2);
                      });
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 296}}

                  , React.createElement(FormControlLabel, {
                    className: classes.radioGroupLabel,
                    value: "1",
                    control: 
                      React.createElement(Radio, {
                        className: classes.radioGroupRadio,
                        color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 317}}
                      )
                    ,
                    label: 
                      React.createElement(React.Fragment, null
                        , !question.answer_1_tooltip ? (
                          question.answer_1_text
                        ) : (
                          React.createElement(React.Fragment, null
                            , question.answer_1_text
                            , React.createElement(CustomTooltip, {
                              className: classes.toolTip,
                              title: question.answer_1_tooltip,
                              placement: "right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 329}}

                              , React.createElement(IconButton, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 334}}
                                , React.createElement(InfoIcon, { fontSize: "small", color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 335}} )
                              )
                            )
                          )
                        )
                      )
                    ,
                    onClick: () => {
                      if (currentValue === "1") {
                        changeQuestion(questionIndex + 2);
                      }
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 313}}
                  )
                  , React.createElement(FormControlLabel, {
                    className: classes.radioGroupLabel,
                    value: "2",
                    control: 
                      React.createElement(Radio, {
                        className: classes.radioGroupRadio,
                        color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 352}}
                      )
                    ,
                    label: 
                      React.createElement(React.Fragment, null
                        , !question.answer_2_tooltip ? (
                          question.answer_2_text
                        ) : (
                          React.createElement(React.Fragment, null
                            , question.answer_2_text
                            , React.createElement(CustomTooltip, {
                              className: classes.toolTip,
                              title: question.answer_2_tooltip,
                              placement: "right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 364}}

                              , React.createElement(IconButton, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 369}}
                                , React.createElement(InfoIcon, { fontSize: "small", color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 370}} )
                              )
                            )
                          )
                        )
                      )
                    ,
                    onClick: () => {
                      if (currentValue === "2") {
                        changeQuestion(questionIndex + 2);
                      }
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 348}}
                  )
                  , React.createElement(FormControlLabel, {
                    className: classes.radioGroupLabel,
                    value: "3",
                    control: 
                      React.createElement(Radio, {
                        className: classes.radioGroupRadio,
                        color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 387}}
                      )
                    ,
                    label: 
                      React.createElement(React.Fragment, null
                        , !question.answer_3_tooltip ? (
                          question.answer_3_text
                        ) : (
                          React.createElement(React.Fragment, null
                            , question.answer_3_text
                            , React.createElement(CustomTooltip, {
                              className: classes.toolTip,
                              title: question.answer_3_tooltip,
                              placement: "right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 399}}

                              , React.createElement(IconButton, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 404}}
                                , React.createElement(InfoIcon, { fontSize: "small", color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 405}} )
                              )
                            )
                          )
                        )
                      )
                    ,
                    onClick: () => {
                      if (currentValue === "3") {
                        changeQuestion(questionIndex + 2);
                      }
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 383}}
                  )
                  , React.createElement(FormControlLabel, {
                    className: classes.radioGroupLabel,
                    value: "4",
                    control: 
                      React.createElement(Radio, {
                        className: classes.radioGroupRadio,
                        color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 422}}
                      )
                    ,
                    label: 
                      React.createElement(React.Fragment, null
                        , !question.answer_4_tooltip ? (
                          question.answer_4_text
                        ) : (
                          React.createElement(React.Fragment, null
                            , question.answer_4_text
                            , React.createElement(CustomTooltip, {
                              className: classes.toolTip,
                              title: question.answer_4_tooltip,
                              placement: "right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 434}}

                              , React.createElement(IconButton, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 439}}
                                , React.createElement(InfoIcon, { fontSize: "small", color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 440}} )
                              )
                            )
                          )
                        )
                      )
                    ,
                    onClick: () => {
                      if (currentValue === "4") {
                        changeQuestion(questionIndex + 2);
                      }
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 418}}
                  )
                  , React.createElement(FormControlLabel, {
                    className: classes.radioGroupLabel,
                    value: "5",
                    control: 
                      React.createElement(Radio, {
                        className: classes.radioGroupRadio,
                        color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 457}}
                      )
                    ,
                    label: 
                      React.createElement(React.Fragment, null
                        , !question.answer_5_tooltip ? (
                          question.answer_5_text
                        ) : (
                          React.createElement(React.Fragment, null
                            , question.answer_5_text
                            , React.createElement(CustomTooltip, {
                              className: classes.toolTip,
                              title: question.answer_5_tooltip,
                              placement: "right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 469}}

                              , React.createElement(IconButton, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 474}}
                                , React.createElement(InfoIcon, { fontSize: "small", color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 475}} )
                              )
                            )
                          )
                        )
                      )
                    ,
                    onClick: () => {
                      if (currentValue === "5") {
                        changeQuestion(questionIndex + 2);
                      }
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 453}}
                  )
                )
              )
            )
          )
        );
      }),
      React.createElement('div', { className: classes.categoryResult, __self: this, __source: {fileName: _jsxFileName, lineNumber: 494}}
        , !categoryIsFinished && (
          React.createElement('div', { className: classes.notFinished, __self: this, __source: {fileName: _jsxFileName, lineNumber: 496}}, "Sie haben noch nicht alle Fragen in diesem Handlungsfeld beantwortet!"


            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 499}} )
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 500}} ), "Per Klick auf die Fragezeichen in der obigen Prozessdarstellung ("

            , 
              React.createElement(Icon, {
                color: theme.palette.primary.main,
                icon: "fa:question-circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 503}}
              )
            , ") können Sie zu der noch unbeantworteten Frage springen."

            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 509}} )
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 510}} ), "Oder setzen Sie den Check über den Button „nächstes Handlungsfeld“ mit weiteren Fragen fort."


          )
        )
        , React.createElement('div', { className: classes.textResult, __self: this, __source: {fileName: _jsxFileName, lineNumber: 515}}
          , categoryIsFinished && (
            React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 517}}, "Für dieses Handlungsfeld erreichen Sie den Reifegrad"
                    , " "
              , answerResult, " (" , maturityLevel[answerResult], ")!"
            )
          )
          , categoryIsFinished && (
            React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 523}}, "Reifegrad "
               , answerResult, " steht für Reifegrad '"
              , maturityLevel[answerResult], "'. " , maturityText[answerResult]
            )
          )
          , categoryIsFinished && (
            React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 529}}
              , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 530}}, "Wenn Sie die Fragen zu allen Handlungsfeldern beantwortet haben, erhalten Sie eine ausführliche Auswertung mit Maßnahmen"


              )
            )
          )
          /* {categoryIsFinished && <ReactMarkdown children={answerResultText} />} */
        )
        , React.createElement('div', { className: classes.chartResult, __self: this, __source: {fileName: _jsxFileName, lineNumber: 538}}
          , categoryIsFinished && (
            React.createElement(AnimatedProgressProvider, {
              valueStart: 0,
              valueEnd: ((answerResult + 1) / 5) * 100,
              duration: 3,
              easingFunction: easeQuadInOut, __self: this, __source: {fileName: _jsxFileName, lineNumber: 540}}

              , (value) => {
                return (
                  React.createElement(CircularProgressbarWithChildren, {
                    value: value,
                    counterClockwise: true,
                    styles: buildStyles({
                      pathTransition: "none",
                      pathColor: theme.palette.primary.main,
                      trailColor: "#d6d6d6",
                    }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 548}}

                    , React.createElement('p', { style: { fontSize: 20, marginTop: -20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 557}}, "Erreichter Reifegrad"

                    )
                    , React.createElement('h1', {
                      style: {
                        fontSize: 50,
                        color: theme.palette.primary.main,
                        padding: 0,
                        margin: 0,
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 560}}

                      , answerResult
                    )
                  )
                );
              }
            )
          )
        )
      ),
    ];

    const [props, set] = useSprings(slides.length, (i) => ({
      x: i * window.innerWidth,
    }));

    return (
      React.createElement('div', { className: classes.pageContainer, __self: this, __source: {fileName: _jsxFileName, lineNumber: 584}}
        , React.createElement('nav', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 585}}
          , React.createElement(Steps, {
            current: index,
            labelPlacement: "vertical",
            className: classes.stepBar, __self: this, __source: {fileName: _jsxFileName, lineNumber: 586}}

            , React.createElement(Steps.Step, {
              key: 0,
              title: "Info",
              icon: React.createElement(Icon, { icon: "fa:info-circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 594}} ),
              onClick: () => {
                changeQuestion(0);
              },
              style: { cursor: "pointer" },
              className: classes.stepStep, __self: this, __source: {fileName: _jsxFileName, lineNumber: 591}}
            )
            , questions.map((question, questionIndex) => (
              React.createElement(Steps.Step, {
                key: questionIndex + 1,
                title: question.name_short,
                icon: 
                  !!app.benchmarks.answers[question.objectId] ? (
                    React.createElement(Icon, { icon: "fa:check", __self: this, __source: {fileName: _jsxFileName, lineNumber: 607}} )
                  ) : (
                    React.createElement(Icon, { icon: "fa:question-circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 609}} )
                  )
                ,
                onClick: () => {
                  changeQuestion(questionIndex + 1);
                },
                style: { cursor: "pointer" },
                className: classes.stepStep, __self: this, __source: {fileName: _jsxFileName, lineNumber: 602}}
              )
            ))
            , React.createElement(Steps.Step, {
              key: questions.length + 1,
              title: "Reifegrad des Handlungsfelds",
              icon: React.createElement(FlagIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 622}} ),
              onClick: () => {
                changeQuestion(questions.length + 1);
              },
              style: { cursor: "pointer" },
              className: classes.stepStep, __self: this, __source: {fileName: _jsxFileName, lineNumber: 619}}
            )
          )
        )

        , React.createElement('div', { className: classes.questionContainer, __self: this, __source: {fileName: _jsxFileName, lineNumber: 632}}
          , slides.map((slide, index) => {
            const { x } = props[index];

            return (
              React.createElement(animated.div, {
                key: index,
                className: classes.question,
                style: {
                  transform: x.interpolate((x) => `translate3d(${x}px,0,0)`),
                },
                children: slide, __self: this, __source: {fileName: _jsxFileName, lineNumber: 637}}
              )
            );
          })
        )

        , React.createElement('div', { className: classes.navigationBar, __self: this, __source: {fileName: _jsxFileName, lineNumber: 649}}
          , index <= questions.length + 1 && (
            React.createElement('div', { style: { display: "flex", gap: "2rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 651}}
              , React.createElement(Button, {
                className: classes.navigationArrow,
                disabled: index === 0,
                onClick: () => {
                  changeQuestion(index - 1);
                },
                color: "primary",
                startIcon: React.createElement(KeyboardArrowLeftRoundedIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 659}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 652}}
, "Vorherige Frage"

              )

              , React.createElement(Button, {
                className: classes.navigationArrow,
                disabled: index === questions.length + 1,
                onClick: () => {
                  changeQuestion(index + 1);
                },
                color: "primary",
                endIcon: React.createElement(KeyboardArrowRightRoundedIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 671}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 664}}
, "Nächste Frage"

              )
            )
          )
        )
      )
    );
  }
);
